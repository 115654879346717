import classNames from 'classnames';
import React from 'react';

import Link from '../components/link';
import RelatedReadingTile from '../components/related-reading-tile';

const SectionRelatedReading = (props) => {
  const {
    heading,
    label,
    reading,
    buttonText,
    buttonSlug,
    tall,
    darkMode,
    withSubline,
    whiteBg,
    noLabel,
    featuredProjects,
  } = props;

  const darkTheme = darkMode || false;
  const relatedClasses = classNames({
    'section': true,
    'section-related-reading': true,
    'section-related-reading--dark': darkTheme,
    'section-related-reading--white': whiteBg,
  });

  return (
    <div className={relatedClasses}>
      <div className="section-related-reading__container">
        <div className="section-related-reading__header">
          {/* If no label text, we still want the label red line */}
          <p className="label">{label || ''}</p>
          {heading && <p className="section-related-reading__heading">{heading}</p>}
        </div>

        {reading.map((reading, i) => {
          const readingId = reading.contentful_id;
          // checks if the teasers to render is of a project featured in the case studies overview page
          const isFeaturedProject = featuredProjects
            ? featuredProjects.some(
                (featuredProject) => featuredProject.contentful_id === readingId
              )
            : false;
          return (
            <RelatedReadingTile
              reading={reading}
              tall={tall}
              withSubline={withSubline}
              noLabel={noLabel}
              featuredCase={isFeaturedProject}
              key={i}
            />
          );
        })}

        {buttonText && buttonSlug && (
          <div className="section-related-reading__cta">
            <Link to={buttonSlug} className="button-icon button-icon--right">
              {buttonText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionRelatedReading;
